import _ from "underscore";
import UIKitButton from "../shared/uikit/form/UIKitButton";
import UIKitForm from "../shared/uikit/form/UIKitForm";
import UIKitSelect from "../shared/uikit/form/UIKitSelect";

// We want to display certain prices as decimal values unless they are whole
// number prices, in which case the decimals can be dropped.
const displayDecimalPriceUnlessWholeNumber = (amount) => amount == parseInt(amount) ? `$${parseInt(amount)}` : `$${amount.toFixed(2)}`;

const CartFormSelect = createReactClass({
  getDefaultProps: function() {
    return {
      buttonClass: "uk-button-primary uk-width-1-1",
      buttonTextStandard: "Add to Cart",
      buttonTextCustom: "Customize",
      addOnce: false
    };
  },

  getInitialState: function() {
    return {
      customizations: {},
      variant: null,
      added: false
    };
  },

  priceToDisplay: function(product) {
    let customizationPrice;
    const configId = (product.design_configurations.map(configuration => configuration.id))[0];
    if (this.state.customizations[configId]) { customizationPrice = parseFloat(this.state.customizations[configId].option.amount); }

    const price = customizationPrice ?
      displayDecimalPriceUnlessWholeNumber(parseFloat(product.price) + customizationPrice) : displayDecimalPriceUnlessWholeNumber(product.price);

    return price;
  },

  addCustomization: function(configuration, option) {
    const newCustomizations = this.state.customizations;
    newCustomizations[configuration.id] = {configuration, option};
    return this.setState({customizations: newCustomizations});
  },

  removeCustomization: function(configuration) {
    const newCustomizations = this.state.customizations;
    delete newCustomizations[configuration.id];
    return this.setState({customizations: newCustomizations});
  },

  addVariant: function(variant) {
    // Remove customizations from old variant first
    if (this.state.variant && this.state.variant.design_configurations) {
      this.state.variant.design_configurations.forEach(configuration => {
        this.removeCustomization(configuration);
      });
    }
    return this.setState({variant});
  },

  removeVariant: function() {
    return this.setState({variant: null});
  },

  handleSubmit: function(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({added: true});
    let v = this.props.product.master;
    if (this.state.variant) { v = this.state.variant; }
    return addToCart(this.props.product, v, _.values(this.state.customizations), this.props.tags, null);
  },

  render: function() {
    const self = this;
    let variant = this.state.variant ? this.state.variant : this.props.product.master;
    const customizations = _.map(variant.design_configurations, configuration => {
      return <DesignConfiguration key={configuration.id} configuration={configuration} product={self.props.product}
                            add={this.addCustomization} remove={this.removeCustomization}/>;
    });

    const variants = <VariantSelector variants={this.props.product.variants_and_option_values_for}
                                 add={this.addVariant} remove={this.removeVariant}/>;

    let buttonText;
    if (_.size(this.state.customizations) > 0) {
      buttonText = this.props.buttonTextCustom;
    } else {
      buttonText = this.props.buttonTextStandard;
    }

    if (this.state.added && this.props.addOnce) {
      return <div className="uk-text-600 uk-text-success uk-animation-fade" style={{height: "42px"}}>
          Added
      </div>;
    } else {
      return <UIKitForm onSubmit={this.handleSubmit} className="uk-form-stacked">
        <div className="uk-container uk-container-center">
          <div className="uk-flex uk-flex-right priceAndProvence">
            <span style={{fontSize: "16px"}}>
              {this.priceToDisplay(this.props.product)}
            </span>
          </div>
        </div>
        <h1 className="uk-text-300 uk-margin-small-top uk-margin-small-bottom" style={{fontSize: "24px", lineHeight: "32px"}}>
        <span className="uk-text-600" style={{fontSize: "17px", lineHeight: "1"}}>
          {this.props.product.vintage}
        </span>
          {<br/>}
          <span style={{fontSize: "24px", lineHeight: "32px"}} dangerouslySetInnerHTML={{__html: this.props.product.non_vintage_name}}/>
        </h1>
        <div className="uk-text-smaller uk-margin-medium-bottom" style={{letterSpacing: "1px", fontStyle: "italic"}}>{this.props.product.mini_description}</div>
          {variants}
          {customizations}
          <div className="uk-margin-top">
              <UIKitButton className={this.props.buttonClass}>{buttonText}</UIKitButton>
          </div>
      </UIKitForm>;
    }
  }
});

var DesignConfiguration = createReactClass({
  handleChange: function(event) {
    if (event.value === 'none') {
      return this.props.remove(this.props.configuration);
    } else {
      const designOption = _.find(this.props.configuration.design_options, designOption => designOption.id === parseInt(event.value));

      return this.props.add(this.props.configuration, designOption);
    }
  },

  render: function() {
    if (!this.props.configuration.design_options.length) { return false; }

    const options = this.props.configuration.design_options.map(option => {
      let displayPrice;
      if (parseFloat(option.amount) === 0) {
        displayPrice = "(included)";
      } else {
        // Display decimals of price if required.
        let amount = displayDecimalPriceUnlessWholeNumber(option.amount)
        if (this.props.product.display_unit_info) {
          displayPrice = `(+ ${amount} per ${this.props.product.unit_label} of ${this.props.product.items_per_unit})`;
        } else {
          displayPrice = `(+ ${amount})`;
        }
      }

      return {
        label: `${option.name} ${displayPrice}`,
        value: option.id
      };
    });

    if (!this.props.configuration.required) {
      options.reverse().push({label: 'None', value: 'none'});
    }

    return <div className="uk-form-row">
        <label className="uk-form-label">Select a customization</label>

        <div className="uk-form-controls">
            <UIKitSelect options={options} name="customization_type" onChange={this.handleChange}
                         defaultValue={options[0].value} className="uk-input uk-width-1-1"/>
        </div>
    </div>;
  }
});


var VariantSelector = createReactClass({
  componentDidMount: function() {
    if (this.props.variants && this.props.variants.length) {
      // window.LabelDesigner.variantSku = @props.variants[0].sku
      return this.props.add(this.props.variants[0]);
    }
  },

  handleChange: function(event) {
    if (typeof document === "undefined") {
      return
    }

    if (event.value === 'none') {
      return this.props.remove;
    } else {
      const variant = _.find(this.props.variants, variant => variant.id === parseInt(event.value));
      this.props.add(variant);

      if (variant.image_url) {
        //focus on a matching image from the slideshow on the page if possible
        const images = $("ul.uk-slideshow-contain li img");
        const urls = [variant.image_url, variant.image_url.replace("/product/","/large/")];
        return _.each(urls, function(url) {
          const index = _.indexOf(_.pluck(images, 'src'), url);
          return $("li[data-uk-slideshow-item="+index+"] a").click();
        });
      }
    }
  },

  render: function() {
    if (!this.props.variants || !this.props.variants.length) { return false; }

    const options = this.props.variants.map(variant => {
      let displayPrice = "";
      if (parseFloat(variant.price) !== 0) {
        displayPrice = displayDecimalPriceUnlessWholeNumber(variant.price);
      }
      return {
        label: `${variant.option_values[0].value_presentation} ${displayPrice}`,
        value: variant.id
      };
    });

    const select_type = this.props.variants[0].option_values[0].type_presentation;
    let select_article = "a";
    if ("aeiou".indexOf(select_type[0].toLowerCase()) >= 0) {
      select_article = "an";
    }
    return <div className="uk-form-row">
        <label className="uk-form-label">Select {select_article} {select_type}</label>

        <div className="uk-form-controls">
            <UIKitSelect options={options} name="variant" onChange={this.handleChange}
                         defaultValue={options[0].value} className="uk-input uk-width-1-1"/>
        </div>
    </div>;
  }
});

var addToCart = function(product, variant, customizations, tags = null, quantity) {
  if (quantity == null) { quantity = 1; }
  if (customizations.length === 0) {
    App.Order.add(variant, quantity, null, {notification: {enabled: false}});
    return Pervino.Routine.CrossSellUpSell.launch(product, () => {
      return Pervino.Cart.open();
    });
  } else {
    return createCustomizations(product, customizations, tags, createdCustomizations => {
      const options = {customizations_attributes: []};

      _.each(createdCustomizations, customization => {
        return options.customizations_attributes.push({
          article_id: customization.design.id,
          article_type: 'Spree::Design',
          configuration_id: customization.configuration.id,
          configuration_type: 'Spree::DesignConfiguration',
          source_id: customization.option.id,
          source_type: 'Spree::DesignOption'
        });
      });

      App.Order.add(variant, quantity, options, {notification: {enabled: false}});
      //# Bug where when cross sell up sell initiates in simple designer, the up sell product is put into scope,
      //# making simple designer think that the sku is that of the up sell product, not the product you are currently on.
      return Pervino.Routine.CrossSellUpSell.launch(product, () => {
        return Pervino.Cart.open();
      });
    });
  }
};


var createCustomizations = function(product, remainingCustomizations, tags, callback, createdCustomizations) {
  if (createdCustomizations == null) { createdCustomizations = []; }
  if (remainingCustomizations.length === 0) {
    callback(createdCustomizations);
    return;
  }

  const nextCustomization = remainingCustomizations.shift();

  const options = {
    tags: (tags || []).concat(nextCustomization.option.tags),
    sku: product.master.sku
  };

  if (nextCustomization.option.simple_designer === true) {
    const settings = {
      simple_designer: nextCustomization.option,
      options
    };

    const simple_size = `${nextCustomization.option.simple_canvas_width}:${nextCustomization.option.simple_canvas_height}`;

    const falseSourceDesign = {
        size: simple_size,
        template_id: null ,
        medium: nextCustomization.option.medium,
        source_id: null
      };

    return Pervino.Routine.CreateDesign.launch(falseSourceDesign, settings, design => {
      createdCustomizations.push(_.extend(nextCustomization, {design}));
      return createCustomizations(product, remainingCustomizations, tags, callback, createdCustomizations);
    });
  } else {
    return Pervino.Routine.SelectDesign.launch(nextCustomization.option.medium, nextCustomization.option.size, nextCustomization.option.default_design, options, design => {
      createdCustomizations.push(_.extend(nextCustomization, {design}));
      return createCustomizations(product, remainingCustomizations, tags, callback, createdCustomizations);
    });
  }
};

export default CartFormSelect;
